import React from 'react';
import { Flex, Button, Icon, Text, Select, useBreakpointValue, Container, Box } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';  // Use useNavigate instead of useHistory
import { FaDonate, FaUsers, FaCarSide, FaHandsHelping, FaHubspot, FaSpa, FaBusinessTime } from 'react-icons/fa';  // Import icons from react-icons

const Navbar = () => {
  const navigate = useNavigate();  // Use useNavigate hook for navigation

  // Use Chakra UI's useBreakpointValue to switch between mobile and desktop views
  const isMobile = useBreakpointValue({ base: true, md: false });

  // Handle select change for mobile view
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedRoute = e.target.value;
    if (selectedRoute) {
      navigate(selectedRoute);  // Navigate to the selected route using useNavigate
    }
  };

  return (
    <>
      {isMobile ? (
        // Mobile view: Use a select dropdown
        <Select placeholder="Navigate" onChange={handleSelectChange} mb={4}>
          <option value="/campaigns">Gofundme Campaigns</option>
          <option value="/missing-people">Missing People</option>
          <option value="/drivers">Supply Runner</option>
          <option value="/volunteering">Volunteering</option>
          <option value="/hubs">Hubs</option>
          <option value="/health-wellness">Health & Wellness</option>
        </Select>
      ) : (
        
        <Flex mb={4} gap={3} justifyContent="center" alignItems="center">
          {/* Gofundme Campaigns */}
          <Link to="/campaigns">
            <Button
              variant="outline"
              colorScheme="blue"
              leftIcon={<Icon as={FaDonate} />}
              size="md"
              px={6}
            >
              <Text fontSize="sm" fontWeight="bold">Gofundme Campaigns</Text>
            </Button>
          </Link>

          {/* Missing People */}
          <Link to="/missing-people">
            <Button
              variant="outline"
              colorScheme="red"
              leftIcon={<Icon as={FaUsers} />}
              size="md"
              px={6}
            >
              <Text fontSize="sm" fontWeight="bold">Missing People</Text>
            </Button>
          </Link>

          {/* Supply Runner */}
          <Link to="/drivers">
            <Button
              variant="outline"
              colorScheme="teal"
              leftIcon={<Icon as={FaCarSide} />}
              size="md"
              px={6}
            >
              <Text fontSize="sm" fontWeight="bold">Supply Runner</Text>
            </Button>
          </Link>

          {/* Volunteering */}
          <Link to="/volunteering">
            <Button
              variant="outline"
              colorScheme="purple"
              leftIcon={<Icon as={FaHandsHelping} />}
              size="md"
              px={6}
            >
              <Text fontSize="sm" fontWeight="bold">Volunteering</Text>
            </Button>
          </Link>

          {/* Hubs */}
          <Link to="/hubs">
            <Button
              variant="outline"
              colorScheme="yellow"
              leftIcon={<Icon as={FaHubspot} />}
              size="md"
              px={6}
            >
              <Text fontSize="sm" fontWeight="bold">Hubs</Text>
            </Button>
          </Link>

          {/* Health & Wellness */}
          <Link to="/health-wellness">
            <Button
              variant="outline"
              colorScheme="pink"
              leftIcon={<Icon as={FaSpa} />}
              size="md"
              px={6}
            >
              <Text fontSize="sm" fontWeight="bold">Health & Wellness</Text>
            </Button>
          </Link>
           {/* Health & Wellness */}
           <Link to="/businesses">
            <Button
              variant="outline"
              colorScheme="#fff"
              leftIcon={<Icon as={FaBusinessTime} />}
              size="md"
              px={6}
            >
              <Text fontSize="sm" fontWeight="bold">Local Businesses</Text>
            </Button>
          </Link>
        </Flex>
        
      )}
    </>
  );
};

export default Navbar;