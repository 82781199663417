// utils/auth.ts
import { supabase } from '../supabaseClient';

export const checkAdminRole = async (): Promise<boolean> => {
  const { data: { session } } = await supabase.auth.getSession();

  if (session) {
    const { data: userProfile } = await supabase
      .from('profiles')
      .select('role')
      .eq('id', session.user.id)
      .single();

    if (userProfile?.role === 'admin') {
      return true;
    }
  }
  return false;
};