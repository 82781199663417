// src/pages/Campaigns.tsx
import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { Box, Heading, Text, Link } from '@chakra-ui/react';

interface Campaign {
  id: number;
  title: string;
  description: string;
  link: string;
  approved: boolean;
}

const Home = () => {
 

  return (
    <Box>
     
    </Box>
  );
};

export default Home;