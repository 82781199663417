import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { Box, Heading, Text, Button, Select, Stack, Flex, Center, Container, useToast, Link, Grid, HStack } from '@chakra-ui/react';
import DynamicFormModal, { FormField } from '../components/DynamicFormModal';

interface Campaign {
  id: number;
  title: string;
  name: string;
  type: string;
  description: string | null;
  link: string;
  approved: boolean;
  image_url?: string;
  venmo: string;
}

const Campaigns = () => {
  const toast = useToast();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState<Campaign[]>([]);
  const [filterType, setFilterType] = useState<string>('all');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [readMore, setReadMore] = useState<{ [key: number]: boolean }>({});

  // Fetching campaigns from Supabase
  useEffect(() => {
    const fetchCampaigns = async () => {
      const { data, error } = await supabase
        .from('campaigns')
        .select('*')
        .eq('approved', true);

      if (error) {
        console.error('Error fetching campaigns:', error);
      } else {
        const fetchedCampaigns = data || [];
        setCampaigns(fetchedCampaigns);
        setFilteredCampaigns(fetchedCampaigns);

        // Fetch image URLs and titles for campaigns missing them
        fetchedCampaigns.forEach(async (campaign) => {
          if (!campaign.image_url || !campaign.title) {
            const scrapeResponse = await fetch('https://bmxscrpvahnmnskmttxi.supabase.co/functions/v1/scrape_campaign', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ url: campaign.link }),
            });

            const scrapeResult = await scrapeResponse.json();
            const title = scrapeResult.title;
            const image_url = scrapeResult.image;

            if (image_url && image_url !== 'No image found') {
              // Update the campaign in Supabase with the new image URL and title
              await supabase
                .from('campaigns')
                .update({ image_url, title })
                .eq('id', campaign.id);

              // Update the local state to reflect the new image URL and title
              setCampaigns((prev) =>
                prev.map((c) => (c.id === campaign.id ? { ...c, image_url, title } : c))
              );
            }
          }
        });
      }
    };

    fetchCampaigns();
  }, []);

  // Filter campaigns by type
  useEffect(() => {
    let filtered = [...campaigns];

    if (filterType !== 'all') {
      filtered = campaigns.filter((campaign) => campaign.type === filterType);
    }

    setFilteredCampaigns(filtered);
  }, [filterType, campaigns]);

  const handleFormSubmit = async (formData: { [key: string]: string | File }) => {
    let { name, type, link, description } = formData;

    if (typeof type === 'string') {
      type = type.toLowerCase();
    }

    const scrapeResponse = await fetch('https://bmxscrpvahnmnskmttxi.supabase.co/functions/v1/scrape_campaign', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url: link }),
    });

    const scrapeResult = await scrapeResponse.json();
    const title = scrapeResult.title || name;
    const image_url = scrapeResult.image;

    const { data, error } = await supabase
      .from('campaigns')
      .insert([{
        name,
        title,
        type: type || "",
        link,
        description: description || null,
        category: 'Campaigns',
        image_url,
        approved: false
      }]);

    if (error) {
      console.error('Error adding campaign:', error.message);
    } else {
      toast({
        title: 'Campaign submitted for approval.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const campaignFields: FormField[] = [
    { label: 'Campaign Name', name: 'name', type: 'text', placeholder: 'Enter campaign name', required: true },
    { label: 'Campaign Type', name: 'type', type: 'select', options: ['Home Relief', 'Business Relief', 'Mutual aid', 'Farm Relief', 'Artist relief', 'Service Industry'], required: true },
    { label: 'GoFundMe Link', name: 'link', type: 'text', placeholder: 'Enter GoFundMe link', required: false },
    { label: 'Venmo', name: 'venmo', type: 'text', placeholder: 'Enter venmo link', required: false },
    { label: 'Description', name: 'description', type: 'textarea', placeholder: 'Enter campaign description' },
  ];

  const toggleReadMore = (id: number) => {
    setReadMore((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const renderDescription = (description: string | null, id: number) => {
    if (!description) {
      return <Text textAlign="center" mb={4}>No description available.</Text>;
    }

    const isExpanded = readMore[id];
    const firstLine = description.split('\n')[0];

    return (
      <Text textAlign="center" mb={4}>
        {isExpanded ? description : `${firstLine}...`}
        {description.length > firstLine.length && (
          <Button variant="link" onClick={() => toggleReadMore(id)} colorScheme="blue" ml={2}>
            {isExpanded ? 'Read Less' : 'Read More'}
          </Button>
        )}
      </Text>
    );
  };

  const renderGoFundMeCard = (campaign: Campaign) => {
    return (
      <Center>
        <Box width="100%" maxW="400px" height="auto" textAlign="center" mx="auto"  overflow="hidden">
          {campaign.image_url && (
            <img src={campaign.image_url} alt={campaign.title} style={{ maxWidth: '100%', height: 'auto' }} />
          )}
          <Heading size="md" mt={4}>{campaign.title}</Heading>
          <Text fontSize="sm" mt={2}>Created by {campaign.name}</Text>
          {renderDescription(campaign.description, campaign.id)}
          {campaign.venmo && (
            <Box textAlign="center" mb={2}>
              <Link href={`https://venmo.com/${campaign.venmo.replace('@', '')}`} isExternal color="blue.500">
                Venmo: {campaign.venmo}
              </Link>
            </Box>
          )}
          {campaign.link && <Text fontSize="lg" color="blue.500" mt={4}>
            <Link href={campaign.link} isExternal>
              View Campaign on GoFundMe
            </Link>
          </Text>}
        </Box>
      </Center>
    );
  };

  return (
    <Container maxW="container.xl" py={10}>
      {/* Page Title */}
      <HStack justifyContent="space-between">
        <Heading as="h1" fontSize="3xl">
          GoFundMe Campaigns
        </Heading>
        <Button colorScheme="blue" onClick={() => setIsModalOpen(true)}>
          + Add New Campaign
        </Button>
      </HStack>
    
      {/* Filter Options */}
      <Flex mb={8} mt={4}>
        <Stack direction="row" spacing={6} width={['100%', '50%', '30%']}>
          <Select value={filterType} onChange={(e) => setFilterType(e.target.value)} placeholder="Filter by type">
            <option value="all">Show All</option>
            <option value="Home Relief">Home Relief</option>
            <option value="Business Relief">Business Relief</option>
            <option value="Mutual aid">Mutual aid</option>
            <option value="Farm Relief">Farm Relief</option>
            <option value="Artist relief">Artist relief</option>
            <option value="Service Industry">Service Industry</option>
          </Select>
        </Stack>
      </Flex>

      {/* Display Campaigns in 4 columns grid */}
      {filteredCampaigns.length > 0 ? (
        <Grid templateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(4, 1fr)']} gap={6}>
          {filteredCampaigns.map((campaign) => (
            <Box
              key={campaign.id}
              p={6}
              boxShadow="md"
              borderRadius="lg"
              bg="white"
              _hover={{ boxShadow: 'lg', cursor: 'pointer' }}
              transition="all 0.3s"
            >
              {renderGoFundMeCard(campaign)}
            </Box>
          ))}
        </Grid>
      ) : (
        <Center>
          <Text>No campaigns found.</Text>
        </Center>
      )}

      {/* DynamicFormModal for adding new campaigns */}
      <DynamicFormModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Add New GoFundMe Campaign"
        formFields={campaignFields}
        onSubmit={handleFormSubmit}
      />
    </Container>
  );
};

export default Campaigns;