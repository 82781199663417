import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { ChakraProvider, Box, Flex, VStack, Text, Image } from '@chakra-ui/react';
import Campaigns from './pages/Campaigns';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import AdminPanel from './pages/AdminPanel';
import MissingPeople from './pages/MissingPeople';
import Volunteering from './pages/Volunteering';
import Drivers from './pages/Drivers';
import Hubs from './pages/Hubs';

const App = () => {
  return (
    <ChakraProvider>
      <Router>
        <Flex minHeight="100vh" direction="column">
          <Box flex="1" >
            <Box background="blue.500" height={2}/>
            {/* Logo */}
            <VStack my={3}>
              <Link to="/">
              <Image src="/wnc.png" alt="WNC Logo" height={120} />
              </Link>
            </VStack>
            {/* Navbar */}
            <Navbar />

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/admin" element={<AdminPanel />} />
              <Route path="/campaigns" element={<Campaigns />} />
              <Route path="/missing-people" element={<MissingPeople />} />
              <Route path="/volunteering" element={<Volunteering />} />
              <Route path="/drivers" element={<Drivers />} />
              <Route path="/hubs" element={<Hubs />} />
              <Route path="/hubs" element={<Hubs />} />
            </Routes>
          </Box>

          {/* Footer */}
          <Flex
            as="footer"
            width="100%"
            p={4}
            backgroundColor="gray.100"
            justifyContent="center"
            position="sticky"
            bottom="0"
          >
            <Text>
              Made with <span role="img" aria-label="love">❤️</span> in Asheville
            </Text>
          </Flex>
        </Flex>
      </Router>
    </ChakraProvider>
  );
};

export default App;