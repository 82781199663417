import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { Box, Heading, Text, Button, Flex, Stack, Container, Center, Input, FormControl, FormLabel } from '@chakra-ui/react';
import { checkAdminRole } from '../utils/auth';  // Utility to check for admin role

interface PendingItem {
  id: number;
  type: string;
  name: string;
  description: string;
  link?: string;
}

const AdminPanel = () => {
  const [pendingItems, setPendingItems] = useState<PendingItem[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState<any>(null); // Store user info
  const [email, setEmail] = useState<string>(''); // For email input
  const [password, setPassword] = useState<string>(''); // For password input
  const [loading, setLoading] = useState<boolean>(false); // Loading state

  useEffect(() => {
    const getUser = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session?.user) {
        setUser(session.user);
        const isAdminUser = await checkAdminRole();
        setIsAdmin(isAdminUser);
        if (isAdminUser) {
          fetchPendingItems();
        }
      }
    };
    getUser();
  }, []);

  const handleLogin = async () => {
    setLoading(true);
    const { error, data } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    setLoading(false);

    if (error) {
      console.error('Login error:', error.message);
      alert('Login failed: ' + error.message);
    } else {
      setUser(data?.user);
      const isAdminUser = await checkAdminRole();
      setIsAdmin(isAdminUser);
      if (isAdminUser) {
        fetchPendingItems();
      } else {
        alert('You are not authorized to access this page.');
      }
    }
  };

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Logout error:', error.message);
    } else {
      setUser(null);
      setIsAdmin(false);
    }
  };

  const fetchPendingItems = async () => {
    const { data: campaigns, error: campaignsError } = await supabase
      .from('resources')
      .select('*')
      .eq('approved', false);

    if (campaignsError) {
      console.error('Error fetching campaigns:', campaignsError);
      return;
    }

    const items = campaigns.map((campaign: any) => ({
      id: campaign.id,
      type: campaign.category,
      name: campaign.name,
      description: campaign.description,
      link: campaign.link,
    }));

    setPendingItems(items);
  };

  const handleApprove = async (id: number) => {
    const { error } = await supabase
      .from('resources')
      .update({ approved: true })
      .eq('id', id);

    if (error) {
      console.error('Error approving item:', error);
    } else {
      setPendingItems(pendingItems.filter(item => item.id !== id));
    }
  };

  const handleReject = async (id: number) => {
    const { error } = await supabase
      .from('resources')
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error rejecting item:', error);
    } else {
      setPendingItems(pendingItems.filter(item => item.id !== id));
    }
  };

  if (!user) {
    return (
      <Center py={10}>
        <Container maxW="md">
          <Heading as="h2" mb={6} textAlign="center">
            Admin Login
          </Heading>
          <FormControl mb={4}>
            <FormLabel>Email</FormLabel>
            <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Password</FormLabel>
            <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </FormControl>
          <Button
            colorScheme="blue"
            onClick={handleLogin}
            isLoading={loading}
            loadingText="Logging in..."
          >
            Login
          </Button>
        </Container>
      </Center>
    );
  }

  if (!isAdmin) {
    return (
      <Center py={10}>
        <Heading size="lg">You are not authorized to access this page.</Heading>
        <Button mt={4} colorScheme="blue" onClick={handleLogout}>Logout</Button>
      </Center>
    );
  }

  return (
    <Container maxW="container.lg" py={10}>
      <Heading as="h1" mb={6} textAlign="center" fontSize="3xl">
        Admin Panel - Pending Approvals
      </Heading>
      <Button mb={4} colorScheme="blue" onClick={handleLogout}>Logout</Button>

      {pendingItems.length > 0 ? (
        <Stack spacing={6}>
          {pendingItems.map(item => (
            <Box key={item.id} p={6} boxShadow="md" borderRadius="lg" border="1px" borderColor="gray.200" bg="white">
              <Heading size="md" mb={4}>{item.name}</Heading>
              <Text>{item.description}</Text>
              {item.link && (
                <Text mt={2}>
                  <a href={item.link} target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
                    View Campaign
                  </a>
                </Text>
              )}
              <Flex mt={4} justifyContent="space-between">
                <Button colorScheme="green" onClick={() => handleApprove(item.id)}>Approve</Button>
                <Button colorScheme="red" onClick={() => handleReject(item.id)}>Reject</Button>
              </Flex>
            </Box>
          ))}
        </Stack>
      ) : (
        <Center>
          <Text>No pending items.</Text>
        </Center>
      )}
    </Container>
  );
};

export default AdminPanel;