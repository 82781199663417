import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import {
  Box,
  Button,
  Heading,
  Input,
  FormControl,
  FormLabel,
  CheckboxGroup,
  Checkbox,
  Stack,
  useToast,
} from '@chakra-ui/react';
import Select from 'react-select';

const DriverSignup = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [availability, setAvailability] = useState<string[]>([]);
  const [areas, setAreas] = useState<string[]>([]);
  const toast = useToast();

  const areaOptions = [
    { value: 'Asheville', label: 'Asheville' },
    { value: 'Weaverville', label: 'Weaverville' },
    { value: 'Black Mountain', label: 'Black Mountain' },
    { value: 'Hendersonville', label: 'Hendersonville' },
    { value: 'Arden', label: 'Arden' },
    { value: 'Mills River', label: 'Mills River' },
    { value: 'Swannanoa', label: 'Swannanoa' },
    { value: 'Brevard', label: 'Brevard' },
    { value: 'Marshall', label: 'Marshall' },
    { value: 'Waynesville', label: 'Waynesville' },
    { value: 'Lake Lure', label: 'Lake Lure' },
    { value: 'Fletcher', label: 'Fletcher' },
    { value: 'Leicester', label: 'Leicester' },
    { value: 'Canton', label: 'Canton' },
    { value: 'Hot Springs', label: 'Hot Springs' },
    { value: 'Burnsville', label: 'Burnsville' },
    { value: 'Etowah', label: 'Etowah' },
    { value: 'Swannanoa', label: 'Swannanoa' },
    { value: 'Fairview', label: 'Fairview' },
    { value: 'Old Fort', label: 'Old Fort' },
  ];

  const handleSubmit = async () => {
    if (!name || !phone || !email || availability.length === 0 || areas.length === 0) {
      toast({
        title: 'Error',
        description: 'Please fill out all fields',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    // Insert driver data into Supabase
    const { error } = await supabase
      .from('drivers')  // Ensure this table exists in Supabase
      .insert([
        {
          name,
          phone,
          email,
          availability: availability.join(', '),  // Store as comma-separated values
          areas: areas.join(', '),  // Store as comma-separated values
        },
      ]);

    if (error) {
      console.error('Error adding driver:', error.message);
      toast({
        title: 'Error signing up as a driver.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Driver signed up successfully!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      // Reset form fields
      setName('');
      setPhone('');
      setEmail('');
      setAvailability([]);
      setAreas([]);
    }
  };

  return (
    <Box maxW="container.md" py={10} mx="auto">
      <Heading as="h1" mb={6} textAlign="center" fontSize="3xl">
        Driver Signup
      </Heading>

      {/* Name Field */}
      <FormControl isRequired mb={4}>
        <FormLabel>Name</FormLabel>
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter your name"
        />
      </FormControl>

      {/* Phone Field */}
      <FormControl isRequired mb={4}>
        <FormLabel>Phone</FormLabel>
        <Input
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          placeholder="Enter your phone number"
        />
      </FormControl>

      {/* Email Field */}
      <FormControl isRequired mb={4}>
        <FormLabel>Email</FormLabel>
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email address"
        />
      </FormControl>

      {/* Availability Field */}
      <FormControl isRequired mb={4}>
        <FormLabel>Availability</FormLabel>
        <CheckboxGroup
          value={availability}
          onChange={(value: string[]) => setAvailability(value)}
        >
          <Stack direction="row">
            <Checkbox value="Morning">Morning</Checkbox>
            <Checkbox value="Afternoon">Afternoon</Checkbox>
            <Checkbox value="Evening">Evening</Checkbox>
          </Stack>
        </CheckboxGroup>
      </FormControl>

      {/* Areas Field */}
      <FormControl isRequired mb={4}>
        <FormLabel>Areas Available to Drive</FormLabel>
        <Select
          isMulti
          options={areaOptions}
          value={areaOptions.filter(option => areas.includes(option.value))}
          onChange={(selectedOptions) =>
            setAreas(selectedOptions.map((option) => option.value))
          }
        />
      </FormControl>

      {/* Submit Button */}
      <Button colorScheme="blue" onClick={handleSubmit} width="full">
        Sign Up as a Driver
      </Button>
    </Box>
  );
};

export default DriverSignup;