import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormControl,
  FormLabel,
  Textarea,
  Select,
  RadioGroup,
  Radio,
  Stack,
  Checkbox,
  FormErrorMessage,
} from '@chakra-ui/react';
import { supabase } from '../supabaseClient';

// Define the type for the form field props
export interface FormField {
  label: string;
  name: string;
  type: 'text' | 'textarea' | 'file' | 'select' | 'checkbox' | 'radio';
  placeholder?: string;
  required?: boolean;
  options?: string[];  // For select, radio, or checkbox fields
}

// Props for the reusable modal
interface DynamicFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  formFields: FormField[];
  onSubmit: (formData: { [key: string]: string | File }) => void;
}

const DynamicFormModal: React.FC<DynamicFormModalProps> = ({ isOpen, onClose, title, formFields, onSubmit }) => {
  const [formData, setFormData] = useState<{ [key: string]: string | File }>({});
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [isUploading, setIsUploading] = useState<boolean>(false);

  // General handle input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: '', // Clear error when user changes the field
    });
  };

  // Specific handle select change
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: '', // Clear error when user changes the field
    });
  };

  // Handle file upload changes
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setFormData({
        ...formData,
        [e.target.name]: file,
      });
      setFormErrors({
        ...formErrors,
        [e.target.name]: '', // Clear error when user selects a file
      });
    }
  };

  // Form validation
  const validateForm = () => {
    const errors: { [key: string]: string } = {};
    formFields.forEach((field) => {
      if (field.required && !formData[field.name]) {
        errors[field.name] = `${field.label} is required`;
      }
    });
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Handle form submit
  const handleSubmit = async () => {
    if (!validateForm()) {
      return; // Do not submit if form is invalid
    }

    // Handle image uploads if there's a file input
    const formDataWithFile = { ...formData };
    for (const field in formData) {
      if (typeof formData[field] === 'object' && formData[field] instanceof File) {
        setIsUploading(true);
        const { data, error } = await supabase.storage
          .from('images') // Replace 'images' with your actual bucket name
          .upload(`public/${Date.now()}_${(formData[field] as File).name}`, formData[field] as File);

        if (error) {
          console.error('Error uploading file:', error);
        } else {
          formDataWithFile[field] = data?.path || ''; // Store the uploaded file path
        }
      }
    }

    setIsUploading(false);
    onSubmit(formDataWithFile);
    onClose(); // Close modal after submission
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Dynamically render form fields */}
          {formFields.map((field) => (
            <FormControl key={field.name} mb={4} isInvalid={!!formErrors[field.name]}>
              <FormLabel>
                {field.label} {field.required && <span style={{ color: 'red' }}>*</span>}
              </FormLabel>
              {field.type === 'text' && (
                <Input
                  name={field.name}
                  placeholder={field.placeholder}
                  value={formData[field.name] as string || ''}
                  onChange={handleChange}
                  isRequired={field.required}
                />
              )}
              {field.type === 'textarea' && (
                <Textarea
                  name={field.name}
                  placeholder={field.placeholder}
                  value={formData[field.name] as string || ''}
                  onChange={handleChange}
                  isRequired={field.required}
                />
              )}
              {field.type === 'select' && (
                <Select
                  name={field.name}
                  onChange={handleSelectChange}
                  value={formData[field.name] as string || ''}
                  isRequired={field.required}
                >
                  {field.options?.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              )}
              {field.type === 'radio' && (
                <RadioGroup
                  name={field.name}
                  onChange={(value) => setFormData({ ...formData, [field.name]: value })}
                >
                  <Stack direction="row">
                    {field.options?.map((option) => (
                      <Radio key={option} value={option}>
                        {option}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              )}
              {field.type === 'checkbox' && (
                <Stack direction="row">
                  {field.options?.map((option) => (
                    <Checkbox
                      key={option}
                      value={option}
                      onChange={(e) =>
                        setFormData({ ...formData, [field.name]: e.target.checked ? option : '' })
                      }
                    >
                      {option}
                    </Checkbox>
                  ))}
                </Stack>
              )}
              {field.type === 'file' && (
                <Input name={field.name} type="file" onChange={handleFileChange} isRequired={field.required} />
              )}
              {formErrors[field.name] && (
                <FormErrorMessage>{formErrors[field.name]}</FormErrorMessage>
              )}
            </FormControl>
          ))}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleSubmit}
            isLoading={isUploading}
            disabled={isUploading}
          >
            Submit
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DynamicFormModal;