import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { Box, Grid, Heading, Text, Button, Input, Stack, Center, Select, useToast, HStack } from '@chakra-ui/react';
import DynamicFormModal, { FormField } from '../components/DynamicFormModal';  // Assuming DynamicFormModal exists
import { LOCATION_LIST } from '../constants';

const Volunteering = () => {
  const [opportunities, setOpportunities] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [searchLocation, setSearchLocation] = useState('');
  const [searchType, setSearchType] = useState('');
  const toast = useToast();

  // Fetch volunteer opportunities data
  const fetchOpportunities = async () => {
    const { data, error } = await supabase.from('volunteer_opportunities').select('*');
    if (error) {
      console.error('Error fetching opportunities:', error);
    } else {
      setOpportunities(data || []);
    }
  };

  useEffect(() => {
    fetchOpportunities();
  }, []);

  // Filter by name, type, and location
  const filteredOpportunities = opportunities.filter((opportunity) =>
    opportunity.name.toLowerCase().includes(searchName.toLowerCase()) &&
    opportunity.location.toLowerCase().includes(searchLocation.toLowerCase()) &&
    (!searchType || opportunity.type === searchType)
  );

  // Handle form submission from DynamicFormModal
  const handleFormSubmit = async (formData: { [key: string]: string | File }) => {
    const { name, type, location, description, website, image } = formData;

    // Handle image upload
    let imagePath = '';
    if (image && image instanceof File) {
      const { data, error } = await supabase.storage
        .from('volunteer_images')  // Your Supabase storage bucket
        .upload(`public/${Date.now()}_${image.name}`, image);

      if (error) {
        console.error('Error uploading image:', error.message);
        return;
      }
      imagePath = data?.path || '';
    }

    // Insert the volunteer opportunity into the database
    const { error } = await supabase
      .from('volunteer_opportunities')
      .insert([
        {
          name,
          type,
          location,
          description,
          website,
          image_url: imagePath,  // Store the image URL
        },
      ]);

    if (error) {
      console.error('Error adding volunteer opportunity:', error.message);
      toast({
        title: 'Error adding volunteer opportunity.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Volunteer opportunity added successfully!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      // Close modal and refetch data
      setIsModalOpen(false);
      fetchOpportunities();  // Refetch data
    }
  };

  // Define form fields for DynamicFormModal
  const formFields: FormField[]  = [
    { label: 'Opportunity Name', name: 'name', type: 'text', placeholder: 'Enter opportunity name', required: true },
    { label: 'Type', name: 'type', type: 'select', required: true, options: ['Environment', 'Health', 'Education', 'Animal Welfare', 'Community Support'] },
    { label: 'Location', name: 'location', type: 'text', placeholder: 'Enter location', required: true },
    { label: 'Description', name: 'description', type: 'textarea', placeholder: 'Enter description', required: true },
    { label: 'Website', name: 'website', type: 'text', placeholder: 'Enter website (optional)', required: false },
    { label: 'Image', name: 'image', type: 'file', required: false },  // Optional image field
  ];

  return (
    <Box maxW="container.xl" py={10} mx="auto">
      <HStack mb={6} justifyContent="space-between">
        <Heading as="h1" textAlign="center" fontSize="3xl">
          Volunteer Opportunities
        </Heading>
        <Button colorScheme="blue" onClick={() => setIsModalOpen(true)}>
          + Add Volunteer Opportunity
        </Button>
      </HStack>
      {/* Filter Options */}
      <Stack direction={['column', 'row']} spacing={4} mb={6} justifyContent="center">
        <Input
          placeholder="Search by name"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        />
 <Select
          placeholder="Search by location"
          onChange={(e) => setSearchLocation(e.target.value)}
          value={searchLocation}>
          {LOCATION_LIST.map((location) => (
            <option key={location} value={location}>
              {location}
            </option>
          ))}
        </Select>
        <Select
          placeholder="Select type"
          value={searchType}
          onChange={(e) => setSearchType(e.target.value)}
        >
          <option value="Environment">Environment</option>
          <option value="Health">Health</option>
          <option value="Education">Education</option>
          <option value="Animal Welfare">Animal Welfare</option>
          <option value="Community Support">Community Support</option>
        </Select>
      </Stack>

      {/* Volunteer Opportunities Cards */}
      <Grid templateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(4, 1fr)']} gap={6}>
        {filteredOpportunities.map((opportunity) => (
          <Box key={opportunity.id} p={4} border="1px" borderRadius="md" boxShadow="md" bg="white">
            {opportunity.image_url && (
              <img
                src={`${supabase.storage.from('volunteer_images').getPublicUrl(opportunity.image_url).data.publicUrl}`}
                alt={opportunity.name}
                style={{ width: '100%', height: '200px', objectFit: 'cover', borderRadius: '8px' }}
              />
            )}
            <Heading size="md" mt={4} textAlign="center">{opportunity.name}</Heading>
            <Text mt={2}><strong>Type:</strong> {opportunity.type}</Text>
            <Text mt={2}><strong>Location:</strong> {opportunity.location}</Text>
            <Text mt={4}>{opportunity.description}</Text>
            {opportunity.website && (
              <Text mt={4}><strong>Website:</strong> <a href={opportunity.website} target="_blank" rel="noopener noreferrer">{opportunity.website}</a></Text>
            )}
          </Box>
        ))}
      </Grid>

      {/* DynamicFormModal for adding new volunteer opportunity */}
      <DynamicFormModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Add Volunteer Opportunity"
        formFields={formFields}
        onSubmit={handleFormSubmit}
      />
    </Box>
  );
};

export default Volunteering;