import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { Box, Grid, Heading, Text, Button, Input, Stack, Center, useToast } from '@chakra-ui/react';
import DynamicFormModal, { FormField } from '../components/DynamicFormModal';  // Assuming DynamicFormModal exists

const MissingPeoplePage = () => {
  const [missingPeople, setMissingPeople] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [searchLocation, setSearchLocation] = useState('');
  const toast = useToast();

  // Define fetchMissingPeople outside useEffect so it can be reused
  const fetchMissingPeople = async () => {
    const { data, error } = await supabase.from('missing_people').select('*');
    if (error) {
      console.error('Error fetching missing people:', error);
    } else {
      setMissingPeople(data || []);
    }
  };

  useEffect(() => {
    fetchMissingPeople();
  }, []);

  // Filter by name and location
  const filteredPeople = missingPeople.filter((person) =>
    person.name.toLowerCase().includes(searchName.toLowerCase()) &&
    person.last_known_location.toLowerCase().includes(searchLocation.toLowerCase())
  );

  // Handle form submission from DynamicFormModal
  const handleFormSubmit = async (formData: { [key: string]: string | File }) => {
    const { name, location, date_last_seen, description, contact_info, image } = formData;

    // Handle image upload
    let imagePath = '';
    if (image && image instanceof File) {
      const { data, error } = await supabase.storage
        .from('images')  // Your Supabase storage bucket
        .upload(`public/${Date.now()}_${image.name}`, image);

      if (error) {
        console.error('Error uploading image:', error.message);
        return;
      }
      imagePath = data?.path || '';
    }

    // Insert the missing person into the database
    const { error } = await supabase
      .from('missing_people')  // Your table name
      .insert([
        {
          name,
          last_known_location: location,
          date_last_seen,
          description,
          contact_info,
          image_url: imagePath,
        },
      ]);

    if (error) {
      console.error('Error adding missing person:', error.message);
      toast({
        title: 'Error adding missing person.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Missing person added successfully!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      // Close modal and reset state
      setIsModalOpen(false);
      fetchMissingPeople();  // Refetch data
    }
  };

  // Define form fields for DynamicFormModal
  const formFields: FormField[] = [
    { label: 'Name', name: 'name', type: 'text', placeholder: 'Enter name', required: true },
    { label: 'Last Known Location', name: 'location', type: 'text', placeholder: 'Enter last known location', required: true },
    { label: 'Date Last Seen', name: 'date_last_seen', type: 'text', placeholder: '', required: true },
    { label: 'Description', name: 'description', type: 'textarea', placeholder: 'Enter description', required: true },
    { label: 'Contact Info (Phone/Email)', name: 'contact_info', type: 'text', placeholder: 'Enter contact information', required: true },
    { label: 'Image', name: 'image', type: 'file', required: false },  // Optional image field
  ];

  return (
    <Box maxW="container.xl" py={10} mx="auto">
      <Heading as="h1" mb={6} textAlign="center" fontSize="3xl">
        Missing People
      </Heading>

      {/* Filter Options */}
      <Stack direction={['column', 'row']} spacing={4} mb={6} justifyContent="center">
        <Input
          placeholder="Search by name"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        />
        <Input
          placeholder="Search by location"
          value={searchLocation}
          onChange={(e) => setSearchLocation(e.target.value)}
        />
      </Stack>

      {/* Missing People Cards */}
      <Grid templateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(4, 1fr)']} gap={6}>
        {filteredPeople.map((person) => (
          <Box key={person.id} p={4} border="1px" borderRadius="md" boxShadow="md" bg="white">
            <img
              src={`${supabase.storage.from('images').getPublicUrl(person.image_url)}`}
              alt={person.name}
              style={{ width: '100%', height: '200px', objectFit: 'cover', borderRadius: '8px' }}
            />
            <Heading size="md" mt={4} textAlign="center">{person.name}</Heading>
            <Text mt={2}><strong>Last seen:</strong> {person.last_known_location}</Text>
            <Text><strong>Date:</strong> {person.date_last_seen}</Text>
            <Text mt={4}>{person.description}</Text>
            <Text mt={4}><strong>Contact:</strong> {person.contact_info}</Text>
          </Box>
        ))}
      </Grid>

      {/* Add Missing Person Button */}
      <Center mt={8}>
        <Button colorScheme="blue" onClick={() => setIsModalOpen(true)}>
          Add Missing Person
        </Button>
      </Center>

      {/* DynamicFormModal for adding new missing people */}
      <DynamicFormModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Add Missing Person"
        formFields={formFields}
        onSubmit={handleFormSubmit}
      />
    </Box>
  );
};

export default MissingPeoplePage;