import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { Box, Grid, Heading, Text, Button, Input, Stack, Center, useToast, Select, HStack } from '@chakra-ui/react';
import DynamicFormModal, { FormField } from '../components/DynamicFormModal';
import { LOCATION_LIST } from '../constants';

const Hubs = () => {
  const [hubs, setHubs] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [searchLocation, setSearchLocation] = useState('');
  const toast = useToast();

  // Fetch hubs data
  const fetchHubs = async () => {
    const { data, error } = await supabase.from('hubs').select('*');
    if (error) {
      console.error('Error fetching hubs:', error);
    } else {
      setHubs(data || []);
    }
  };

  useEffect(() => {
    fetchHubs();
  }, []);

  // Filter by name and location
  const filteredHubs = hubs.filter((hub) =>
    hub.name.toLowerCase().includes(searchName.toLowerCase()) &&
    hub.location.toLowerCase().includes(searchLocation.toLowerCase())
  );

  // Handle form submission from DynamicFormModal
  const handleFormSubmit = async (formData: { [key: string]: string | File }) => {
    const { name, location, inventory, phone, email, social_media, image } = formData;

    // Handle image upload
    let imagePath = '';
    if (image && image instanceof File) {
      const { data, error } = await supabase.storage
        .from('hub_images')
        .upload(`public/${Date.now()}_${image.name}`, image);

      if (error) {
        console.error('Error uploading image:', error.message);
        return;
      }
      imagePath = data?.path || '';
    }

    // Insert the hub data into the database
    const { error } = await supabase
      .from('hubs')
      .insert([
        {
          name,
          location,
          inventory,
          phone,
          email,
          social_media,
          image_url: imagePath, // Store the image URL
        },
      ]);

    if (error) {
      console.error('Error adding hub:', error.message);
      toast({
        title: 'Error adding hub.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Hub added successfully!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      // Close modal and refetch data
      setIsModalOpen(false);
      fetchHubs(); // Refetch data
    }
  };

  // Define form fields for DynamicFormModal
  const formFields: FormField[] = [
    { label: 'Hub Name', name: 'name', type: 'text', placeholder: 'Enter hub name', required: true },
    { label: 'Location', name: 'location', type: 'select', required: true, options: LOCATION_LIST},
    { label: 'Inventory', name: 'inventory', type: 'textarea', placeholder: 'Enter available inventory (e.g., food, water, blankets)', required: true },
    { label: 'Phone Number', name: 'phone', type: 'text', placeholder: 'Enter phone number', required: true },
    { label: 'Email', name: 'email', type: 'text', placeholder: 'Enter email address', required: true },
    { label: 'Social Media', name: 'social_media', type: 'text', placeholder: 'Enter social media link (optional)', required: false },
    { label: 'Image', name: 'image', type: 'file', required: false }, // Optional image field
  ];

  return (
    <Box maxW="container.xl" py={10} mx="auto">
      <HStack justifyContent="space-between"  alignItems="center" mb={4}>
        <Heading as="h1"  textAlign="center" fontSize="3xl">
          Hubs with Supplies
        </Heading>
        <Button colorScheme="blue" onClick={() => setIsModalOpen(true)}>
          + Add New Hub
        </Button>
      </HStack>
      
      {/* Filter Options */}
      <Stack direction={['column', 'row']} spacing={4} mb={6} justifyContent="center">
        <Input
          placeholder="Search by hub name"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        />
         <Select
          placeholder="Search by location"
          onChange={(e) => setSearchLocation(e.target.value)}
          value={searchLocation}>
          {LOCATION_LIST.map((location) => (
            <option key={location} value={location}>
              {location}
            </option>
          ))}
        </Select>
      </Stack>

      {/* Hubs Cards */}
      <Grid templateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(4, 1fr)']} gap={6}>
        {filteredHubs.map((hub) => (
          <Box key={hub.id} p={4} border="1px" borderRadius="md" boxShadow="md" bg="white">
            {hub.image_url && (
              <img
                src={`${supabase.storage.from('hub_images').getPublicUrl(hub.image_url).data.publicUrl}`}
                alt={hub.name}
                style={{ width: '100%', height: '200px', objectFit: 'cover', borderRadius: '8px' }}
              />
            )}
            <Heading size="md" mt={4} textAlign="center">{hub.name}</Heading>
            <Text mt={2}><strong>Location:</strong> {hub.location}</Text>
            <Text mt={2}><strong>Inventory:</strong> {hub.inventory}</Text>
            <Text mt={4}><strong>Phone:</strong> {hub.phone}</Text>
            <Text mt={2}><strong>Email:</strong> {hub.email}</Text>
            {hub.social_media && (
              <Text mt={4}><strong>Social Media:</strong> <a href={hub.social_media} target="_blank" rel="noopener noreferrer">{hub.social_media}</a></Text>
            )}
          </Box>
        ))}
      </Grid>

      {/* DynamicFormModal for adding new hub */}
      <DynamicFormModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Add New Hub"
        formFields={formFields}
        onSubmit={handleFormSubmit}
      />
    </Box>
  );
};

export default Hubs;